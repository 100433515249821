import React, { useEffect, useRef } from 'react';

const ToggleTimFooterOnMobileResize = ({ children }) => {
  const containerRef = useRef(null);

  // Dynamically ensure menus are initially hidden
  useEffect(() => {
    const container = containerRef.current;
    const checkInterval = setInterval(() => {
      const menus = container.querySelectorAll('.menu');
      menus.forEach((menu) => {
        if (!menu.classList.contains('hidden')) {
          menu.classList.add('hidden');
        }
      });
    }, 100); // Check every 100 milliseconds

    // Cleanup interval when component unmounts
    return () => clearInterval(checkInterval);
  }, []);

  // Handle resize and click interactions
  useEffect(() => {
    const container = containerRef.current;

    const adjustMenuVisibility = () => {
      const isMobile = window.innerWidth < 768;
      const menus = container.querySelectorAll('.menu');
      menus.forEach((menu) => {
        if (isMobile) {
          menu.classList.add('hidden');
        } else {
          menu.classList.remove('hidden');
        }
      });
    };

    const handleClick = (event) => {
      if (window.innerWidth < 768 && event.target.classList.contains('timglobal-block-h3')) {
        const menu = event.target.nextElementSibling; // Menu right after timglobal-block-h3
        if (menu && menu.classList.contains('menu')) {
          menu.classList.toggle('show'); // Toggle visibility on click
        }
      }
    };

    // Initial adjustment and setup event listeners
    adjustMenuVisibility();
    container.addEventListener('click', handleClick);
    window.addEventListener('resize', adjustMenuVisibility);

    return () => {
      container.removeEventListener('click', handleClick);
      window.removeEventListener('resize', adjustMenuVisibility);
    };
  }, []);

  return (
    <div ref={containerRef} className="menu-container">
      {children}
    </div>
  );
};

export default ToggleTimFooterOnMobileResize;

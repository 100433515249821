import React, { Component } from 'react';
import PropTypes from 'prop-types';

class TimTab extends Component {
  static propTypes = {
    activeTab: PropTypes.string.isRequired,
    navLabel: PropTypes.string.isRequired,
    onClick: PropTypes.func.isRequired,
    navId: PropTypes.string.isRequired,
  };

  onClick = () => {
    const { navLabel, onClick } = this.props;
    onClick(navLabel);
  };

  render() {
    const {
      onClick,
      props: { activeTab, navLabel },
    } = this;

    let navId = `TimNav-${navLabel.replace(/\s/g, '')}`;

    let className = 'tab-list-item ';

    let logoCheck = 'TimNav-VideoVault';
    if (logoCheck === navId) {
      className += ' tab-logo-nav';
    }

    if (activeTab === navLabel) {
      className += ' tab-list-active';
    }

    return (
      <li id={navId} className={className} onClick={onClick}>
        {navLabel}
      </li>
    );
  }
}

export default TimTab;

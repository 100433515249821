import React, { useEffect } from 'react';

import { isUserLoggedIn } from '../../../services/token-service/token.service';

function LoggedInToWelcome() {
  const userLoggedIn = isUserLoggedIn();

  useEffect(() => {
    let timeout;

    if (userLoggedIn) {
      timeout = setTimeout(() => {
        window.location.href = '/';
      }, 3000);
    }

    return () => clearTimeout(timeout);
  }, [userLoggedIn]);

  return (
    <>
      {userLoggedIn ? (
        <h2 className="SSOMessage">
          SIGNED IN <em>(one moment sending you to welcome page)</em>
        </h2>
      ) : (
        <h2 className="SSOMessage">
          LOGGED OUT.{' '}
          <a href="https://treasureislandmedia.com/tvvsignup" target="_blank" rel="noopener noreferrer">
            Need a membership?
          </a>
        </h2>
      )}
    </>
  );
}

export default LoggedInToWelcome;
